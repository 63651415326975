export interface IRoleProps{
    id:number;
    role:string;
}

export class Role implements IRoleProps{
    constructor(
        public id:number,
        public role:string,
    ){}
}