import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { Endpoints } from '../Helpers/ApiEndpoints';
import { ITicketProps } from '../Helpers/TicketInterfaces';
import { DataPrepare } from '../Helpers/DataPrepare';
import { useNavigate } from 'react-router-dom';

function Tickets(){

    const[tickets, setTickets] = useState<Array<ITicketProps>>([]);

    const apiEndpoints = new Endpoints();
    const prepareData = new DataPrepare();
    const navigate = useNavigate();

    async function get_tickets(){
        try{
            const response = await apiEndpoints.getTickets();
            console.log(response)
            const preparedTickets = prepareData.prepareTickets(response.data.tickets)
            setTickets(preparedTickets);
        }catch(error){
            console.log(error)
        }
        
    }

    useEffect(()=>{
        get_tickets()
    },[])

    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead >
                <TableRow>
                    <TableCell>Contact</TableCell>
                    <TableCell align="left">Subject</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Last Response</TableCell>
                    <TableCell align="left">Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {tickets && tickets.map((ticket, key)=>{
                        const formattedDate = new Intl.DateTimeFormat("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }).format(ticket.ticketDate);
                        return(
                                <TableRow
                                    sx={{  cursor: 'pointer'}}
                                    hover={true}
                                    onClick={() => navigate(`${ticket.ticketID}`)}
                                    key={key}
                                >
                                    <TableCell component="th" scope="row">
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                        <div style={{border:'solid 1px grey', borderRadius:'300px', marginRight:'12px', minWidth:'35px', minHeight:'35px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
                                        <p style={{margin:'0px', width:'fit-content'}}>{ticket.firstName.charAt(0) + ticket.lastName.charAt(0)}</p>
                                        </div>
                                    </div> 
                                    </TableCell>
                                    <TableCell align="left" sx={{maxWidth:'220px'}}><p style={{margin:'0px', maxWidth:'500px'}}>{ticket.ticketSubject}</p></TableCell>
                                    <TableCell align="left"><div>{ticket.category}</div></TableCell>
                                    <TableCell align="left">{formattedDate}</TableCell>
                                    <TableCell align="left">{ticket.ticketStatus}</TableCell>
                                </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            </TableContainer>
        </div>
    );
}
export default Tickets;