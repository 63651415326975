import { Delete, HighlightOff, MoreVert, CheckCircleOutline } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardHeader, IconButton, Popover, Snackbar, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ISingleTicketProps } from '../Helpers/TicketInterfaces';
import { Endpoints } from '../Helpers/ApiEndpoints';
import { DataPrepare } from '../Helpers/DataPrepare';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';

interface ISnackBarProps{
  open:boolean;
  onClose:undefined | (() => void);
  text:string | undefined;
}

class SnackBar implements ISnackBarProps{
  constructor(
    public open:boolean,
    public onClose: undefined | (() => void),
    public text:string | undefined,
  ){}
}

function SingleTicket(){

    const[ticket, setTicket] = useState<ISingleTicketProps>();
    const[open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const{state} = useAuthContext();
    const[snackbarProps, setSnackBarProps] = useState<ISnackBarProps>(new SnackBar(false, undefined, undefined))

    const apiEndpoints = new Endpoints();
    const prepareData = new DataPrepare();

    async function getTicket(){
        if(id){
            const ticketID = Number(id)
            try{
                const response = await apiEndpoints.getTicket(ticketID);
                const preparedTicket = prepareData.prepareTicket(response.data)
                setTicket(preparedTicket);
            }catch(error){
                throw error
            }
        }else{
            throw new Error('No ticket ID passed as URL param.')
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
      };

    useEffect(()=>{
        getTicket();
    },[])

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
      };


    async function handleChangeTicketStatusToClose(){
        const ticketID = Number(id)
        if (ticketID){
            try{
                const response = await apiEndpoints.closeTicket(ticketID);
                setSnackBarProps({open:true, onClose:handleChangeTicketStatusToClose, text:'Ticket Closed'})
            }catch(error){
                throw error
            }
        }
    }

    async function handleChangeTicketStatusToOpen(){
        const ticketID = Number(id)
        if (ticketID){
            try{
                const response = await apiEndpoints.openTicket(ticketID);
                setSnackBarProps({open:true, onClose:handleChangeTicketStatusToOpen, text:'Ticket Opened'})
            }catch(error){
                throw error
            }
        }
    }

    async function handleDeleteTicket(){
        const ticketID = Number(id)
        if (ticketID){
            try{
                const response = await apiEndpoints.deleteTicket(ticketID);
                console.log(response);
                navigate('/tickets')
            }catch(error){
                throw error
            }
        }
    }

    return(
      <div style={{width:'100%', height:'100%'}}>
        <Box sx={{ width:'100%' }}>
            <Card>
              <CardHeader 
              avatar={
                <Avatar sx={{ bgcolor: '#f44336' }} aria-label="recipe">
                  {ticket && ticket.firstName ? Array.from(ticket.firstName)[0] : 'N'} 
                  {ticket && ticket.lastName ? Array.from(ticket.firstName)[0] : 'A'}
                </Avatar>}
              title={ticket?.ticketSubject}
              titleTypographyProps={{variant:'h4'}}
              subheader={ticket?.ticketDate.toDateString()}
              action={
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVert />
                </IconButton>
              }/>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                style={{borderRadius:'200px'}}
              >
                <div className='d-flex flex-column px-1 py-1'>

                {ticket?.ticketStatus === 'Open' && 
                    <Tooltip title="Close Ticket" placement="left-start">
                    <IconButton aria-label="settings" onClick={handleChangeTicketStatusToClose}>
                        <HighlightOff />
                    </IconButton>
                    </Tooltip>
                }
                {ticket?.ticketStatus === 'Closed' && 
                    <Tooltip title="Open Ticket" placement="left-start">
                    <IconButton aria-label="settings" onClick={handleChangeTicketStatusToOpen}>
                        <CheckCircleOutline />
                    </IconButton>
                    </Tooltip>
                }

                { state.loginDetails?.permissionLevel == 2 && <Tooltip title="Delete Ticket" placement="left-start">
                  <IconButton className='mt-2' aria-label="settings" onClick={handleDeleteTicket}>
                    <Delete />
                  </IconButton>
                </Tooltip>}
                </div>
              </Popover>
              <CardContent>
                {ticket && ticket.ticketBody ? ticket.ticketBody : ''} 
              </CardContent>
            </Card>
          </Box>
          <Snackbar
            open={snackbarProps?.open}
            autoHideDuration={6000}
            onClose={snackbarProps.onClose}
            message={snackbarProps.text}
          />    
      </div>
        
    );
}
export default SingleTicket