import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Endpoints, IBuildResponseProps } from '../Helpers/ApiEndpoints';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';
import { DataPrepare } from '../Helpers/DataPrepare';
import { useNavigate } from 'react-router-dom';

function SignUp(){

    const[submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const[firstName, setFirstName] = useState<string>('');
    const[lastName, setLastName] = useState<string>('');
    const[email, setEmail] = useState<string>('');
    const[password, setPassword] = useState<string>('');
    const[confirmPassword, setConfirmPassword] = useState<string>('');
    const[isPassInvalid, setIsPassInvalid] = useState<boolean>(true);

    const {dispatch} = useAuthContext();
    const navigate = useNavigate();

    useEffect(()=>{
        confirmFormCompleted()
    },[firstName, lastName, email, password, confirmPassword])

    function confirmFormCompleted(){
        if(firstName && lastName && email && password && confirmPassword){
            if(password === confirmPassword){
                setSubmitDisabled(false);
                setIsPassInvalid(false);
            }else{
                setSubmitDisabled(true);
                setIsPassInvalid(true);
            }
        }
    }

    async function submitForm(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();

        
        const apiEndpoints = new Endpoints();
        const prepareData = new DataPrepare();
        const payload = {'firstName':firstName, 'lastName':lastName, 'email':email, 'password':password}
        try{
            const response:IBuildResponseProps = await apiEndpoints.signUp(payload);
            console.log(response);
            let loginDetails = prepareData.prepareLoginResponseForStorage(response.data);
            if(!response.success){
                throw new Error('error logging in')
              }else{
                dispatch({type: 'SET_USER', payload:loginDetails});
                navigate('/');
              }
        }catch(error){
            console.log(error)
        }
    }

    return(
        <div className='loginPageWrapper' style={{display:'flex', width:'100%', height:'100vh', justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
            <div className='loginContainer' style={{display:'flex', maxWidth:'400px', maxHeight:'fit-content', width:'100%', height:'100%', backgroundColor:'lightgray', padding:'20px', boxSizing:'border-box', flexDirection:'column', gap:'30px'}}>
                <Form noValidate>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="John" value={firstName} onChange={(e)=>setFirstName(e.currentTarget.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Doe" value={lastName} onChange={(e)=>setLastName(e.currentTarget.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e)=>setEmail(e.currentTarget.value)} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.currentTarget.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.currentTarget.value)} required isInvalid={isPassInvalid}/>
                        <Form.Control.Feedback type="invalid">
                            Passwords must be the same.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={(e)=>submitForm(e)} disabled={submitDisabled}>
                        Sign Up
                    </Button>
                </Form>
            </div>
        </div>
    );
}
export default SignUp