import * as React from 'react';
import '../../App.css';
import HomeIcon from '@mui/icons-material/Home';
import SideBarButton from './SideBarButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SideBarButtonDropDownWrapper from './SideBarDropDownWrapper';
import PersonIcon from '@mui/icons-material/Person';
import SideBarButtonDropDownItemsContainer from './SideBarDropDownItemsContainer';
import SideBarDropDownItem from './SideBarDropDownItem';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

function SideBar(){
    return(
        <div style={{display:'flex', boxSizing:'border-box', height:'100%', width:'285px', backgroundColor:'var(--white)', flexShrink:'0', borderRight: 'solid 1px #e1e1e1'}}>
            <div style={{display:'flex', boxSizing:'border-box', width:'100%', padding:'25px 10px 10px 10px', flexDirection:'column', gap:'4px'}}>
                <SideBarButton icon={<HomeIcon/>} text='Dashboard' route='/dashboard' permissionLevel={1}/>
                <SideBarButtonDropDownWrapper permissionLevel={1}>
                    <SideBarButton icon={<ConfirmationNumberIcon/>} text='Tickets' route='/tickets' permissionLevel={1}/>
                    <SideBarButtonDropDownItemsContainer parentPath='/tickets'>
                        <SideBarDropDownItem text='New Ticket' route='/tickets/new'/>
                    </SideBarButtonDropDownItemsContainer>
                </SideBarButtonDropDownWrapper>
                <SideBarButtonDropDownWrapper permissionLevel={2}>
                    <SideBarButton icon={<PersonIcon/>} text='Users' route='/users' permissionLevel={2}/>
                    <SideBarButtonDropDownItemsContainer parentPath='/users'>
                        <SideBarDropDownItem text='Add New User' route='/users/new'/>
                        <SideBarDropDownItem text='Account' route='/users/account'/>
                    </SideBarButtonDropDownItemsContainer>
                </SideBarButtonDropDownWrapper>
                <SideBarButton icon={<SettingsIcon/>} text='Settings' route='/settings' permissionLevel={1}/>
                <SideBarButton icon={<LogoutIcon/>} text='Sign Out' route='/signout' permissionLevel={1}/>
            </div>
        </div>
    );
}
export default React.memo(SideBar);