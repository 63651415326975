import * as React from 'react';
import '../layout.scss';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useAuthContext } from '../../Reusable Components/Context Providers/AuthContextProvider';

interface ISideBarButtonProps{
    children:React.ReactNode;
    permissionLevel:number;
}

function SideBarButtonDropDownWrapper(props: ISideBarButtonProps){

    const{
        children,
        permissionLevel
    } = props;

    const navigate = useNavigate();

    const{state} = useAuthContext();

    if(state.loginDetails && state.loginDetails.permissionLevel >= permissionLevel){
        return(
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                {children}
            </div>
        )
    }else{
        return(<></>)
    }
    
}
export default React.memo(SideBarButtonDropDownWrapper)