import axios, { AxiosResponse, HttpStatusCode } from "axios";

export interface ILoginPayloadProps{
    email: string;
    password: string
}

export interface IBuildResponseProps{
  status:HttpStatusCode;
  message:string;
  success:boolean;
  headers?:any;
  data?:any;
}

export class BuildResponse implements IBuildResponseProps{
  constructor(
    public status:HttpStatusCode,
    public message:string,
    public success:boolean,
    public headers?:any,
    public data?:any,
  ){}
}

axios.defaults.withCredentials = true;

export class Endpoints {

    async login(payload: ILoginPayloadProps) {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/auth/login`, payload);
          const preparedResponse:IBuildResponseProps = response.data;
          return preparedResponse;
        } catch (error) {
          console.error('There was an error!', error);
          throw error;
        }
        
    }

    async signUp(payload: ILoginPayloadProps): Promise<IBuildResponseProps> {
      return new Promise(async (resolve, reject) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/auth/signup`, payload);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          const errorResponse = new BuildResponse(400, 'An error occurred while processing your request.', false);
          reject(errorResponse)
          console.error('There was an error!', error);
          throw error;
        }
      });
    }

    async authMe(): Promise<IBuildResponseProps>{
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      try{
        const response = await axios.get(`${apiUrl}/auth/me`);
        const preparedResponse:IBuildResponseProps = response.data;
        return preparedResponse;
      }catch(error){
        throw error
      }
    }

    async getTickets():Promise<IBuildResponseProps> {
      return new Promise(async (resolve) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.get(`${apiUrl}/tickets/gettickets`);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          throw error
        }
      });
    }

    async getTicket(payload:number):Promise<IBuildResponseProps> {
      return new Promise(async (resolve) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/tickets/getticket`, {id:payload});
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          throw error
        }
      });
    }

    async closeTicket(payload:number):Promise<IBuildResponseProps> {
      return new Promise(async (resolve) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/tickets/closeticket`, {id:payload});
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          throw error
        }
      });
    }

    async openTicket(payload:number):Promise<IBuildResponseProps> {
      return new Promise(async (resolve) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/tickets/openticket`, {id:payload});
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          throw error
        }
      });
    }

    async deleteTicket(payload:number):Promise<IBuildResponseProps> {
      return new Promise(async (resolve) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/tickets/deleteticket`, {id:payload});
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          throw error
        }
      });
    }

    async logOut():Promise<IBuildResponseProps> {
      return new Promise(async (resolve) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/auth/logout`);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          throw error
        }
      });
    }

    async createUser(payload: ILoginPayloadProps): Promise<IBuildResponseProps> {
      return new Promise(async (resolve, reject) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/users/createuser`, payload);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          const errorResponse = new BuildResponse(400, 'An error occurred while processing your request.', false);
          reject(errorResponse)
          console.error('There was an error!', error);
          throw error;
        }
      });
    }

    async getRoles(): Promise<IBuildResponseProps> {
      return new Promise(async (resolve, reject) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.get(`${apiUrl}/users/getroles`);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          const errorResponse = new BuildResponse(400, 'An error occurred while processing your request.', false);
          reject(errorResponse)
          console.error('There was an error!', error);
          throw error;
        }
      });
    }

    async getCategories(): Promise<IBuildResponseProps> {
      return new Promise(async (resolve, reject) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.get(`${apiUrl}/tickets/getcategories`);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          const errorResponse = new BuildResponse(400, 'An error occurred while processing your request.', false);
          reject(errorResponse)
          console.error('There was an error!', error);
          throw error;
        }
      });
    }

    async createTicket(payload: any): Promise<IBuildResponseProps> {
      return new Promise(async (resolve, reject) =>{
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/tickets/createticket`, payload);
          const preparedResponse:IBuildResponseProps = response.data;
          resolve(preparedResponse)
        } catch (error) {
          const errorResponse = new BuildResponse(400, 'An error occurred while processing your request.', false);
          reject(errorResponse)
          console.error('There was an error!', error);
          throw error;
        }
      });
    }
}