import { LoginDetails, UserDetails } from "./AuthInterface";
import { IRoleProps, Role } from "./RolesInterfaces";
import { Category, ITicketCategoryProps, ITicketProps, SingleTicket, Ticket } from "./TicketInterfaces";

export class DataPrepare{

    prepareLoginResponseForStorage(payload:any){
        console.log(payload);
        const userDetails = new UserDetails(payload.firstName, payload.lastName, payload.email, payload.picture? payload.picture : undefined);
        const loginDetails = new LoginDetails(payload.permissionLevel, userDetails);
        return loginDetails
    }

    // category
    // : 
    // "Hardware"
    // first_name
    // : 
    // "Michael"
    // last_name
    // : 
    // "Smith"
    // ticket_body
    // : 
    // "The network printer in office 305 has been jamming frequently and now it's not printing at all. The screen says \"Out of Paper,\" but the tray is full."
    // ticket_date
    // : 
    // "Thu, 21 Sep 2023 17:42:17 GMT"
    // ticket_id
    // : 
    // 19
    // ticket_status
    // : 
    // "Closed"
    // ticket_subject
    // : 
    // "Printer Not Working in Office 305"

    prepareTickets(payload:any){
        let ticketsArray:ITicketProps[] = [];

        for(const key of Object.keys(payload)){
            const singleTicket = payload[Number(key)]
            const dateObject = new Date(singleTicket.ticket_date);
            ticketsArray.push(new Ticket(singleTicket.category, singleTicket.first_name, singleTicket.last_name, dateObject, singleTicket.ticket_id, singleTicket.ticket_status, singleTicket.ticket_subject))
        }

        return ticketsArray
    }

    prepareTicket(singleTicket:any){
            const dateObject = new Date(singleTicket.ticket_date);
            let preparedTicket = new SingleTicket(singleTicket.category, singleTicket.first_name, singleTicket.last_name,singleTicket.ticket_body, dateObject, singleTicket.ticket_id, singleTicket.ticket_status, singleTicket.ticket_subject);
        return preparedTicket
    }

    prepareRoles(payload:any){
        let roles:IRoleProps[] = []

        for(const key of Object.keys(payload)){
            const singleRole = payload[Number(key)]
            roles.push(new Role(singleRole.id, singleRole.role))
        }
        
        return roles
    }

    prepareCategories(payload:any){
        let categories:ITicketCategoryProps[] = []

        for(const key of Object.keys(payload)){
            const singleCategory = payload[Number(key)]
            categories.push(new Category(singleCategory.id, singleCategory.category))
        }
        
        return categories
    }
    
}