import { Delete, HighlightOff, MoreVert, CheckCircleOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, CardHeader, FormControl, IconButton, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Snackbar, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ISingleTicketProps } from '../Helpers/TicketInterfaces';
import { Endpoints, IBuildResponseProps } from '../Helpers/ApiEndpoints';
import { DataPrepare } from '../Helpers/DataPrepare';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';
import { IRoleProps } from '../Helpers/RolesInterfaces';

interface ISnackBarProps{
  open:boolean;
  onClose:undefined | (() => void);
  text:string | undefined;
}

class SnackBar implements ISnackBarProps{
  constructor(
    public open:boolean,
    public onClose: undefined | (() => void),
    public text:string | undefined,
  ){}
}

function CreateUser(){

    const [roles, setRoles] = useState<IRoleProps[]>([]);
    const [role, setRole] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [fName, setFName] = useState<string>('');
    const [lName, setLName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [initialising, setInitialising] = useState<boolean>(true)
    const[snackbarProps, setSnackBarProps] = useState<ISnackBarProps>(new SnackBar(false, undefined, undefined))

    const endpoints = new Endpoints();
    const prepareData = new DataPrepare();

    function handleRoleChange(event: SelectChangeEvent) {
      
        setRole(event.target.value as string);
        console.log(role);
    }
  
    function userEmailOnchange(event: React.ChangeEvent<HTMLInputElement>){
      setUserEmail(event.currentTarget.value);
      
    }
  
    function fNameOnchange(event: React.ChangeEvent<HTMLInputElement>){
      setFName(event.currentTarget.value);
      
    }
  
    function lNameOnchange(event: React.ChangeEvent<HTMLInputElement>){
      setLName(event.currentTarget.value);
      
    }
  
    function passwordOnchange(event: React.ChangeEvent<HTMLInputElement>){
      setPassword(event.currentTarget.value);
      
    }

    async function getRoles(){
      try{
        const response:IBuildResponseProps = await endpoints.getRoles();
        const preparedRoles:IRoleProps[] = prepareData.prepareRoles(response.data);
        setRoles(preparedRoles);
        console.log(preparedRoles);
        setInitialising(false)
      }catch(error){
        throw error
      }
    }

    async function submitForm(){
      const payload = {'firstName':fName, 'lastName':lName, 'email':userEmail, 'password':password, 'roleId':role}
      try{
        const response:IBuildResponseProps = await endpoints.createUser(payload);
        console.log(response)
        setSnackBarProps({open:true, onClose:submitForm, text:'User Created.'});
        resetForm();
      }catch(error){
        throw error
      }
    }

    function resetForm(){
      setRole('');
      setUserEmail('');
      setFName('');
      setLName('');
      setPassword('');
    }

    useEffect(()=>{
      getRoles();
    },[])

    if(initialising){
      return(<></>)
    }else{
      return(
        <div style={{width:'100%', height:'100%'}}>
          <Box
          component="form"
          sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },display:'flex', flexDirection:'column', width:'100%', alignItems:'center'
          }}
          noValidate
          autoComplete="off">

            <div className='d-flex' style={{width:'100%'}}>

              <TextField
              required
              id="outlined-required"
              label="User Email"
              value={userEmail}
              onChange={userEmailOnchange}
              />
              <FormControl fullWidth sx={{ m: 1, minWidth: 232, maxWidth:232 }}>
                <InputLabel id="demo-simple-select-label">User Role</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                sx={{flexGrow:'1'}}
                label="User Role"
                value={role}
                defaultValue='1'
                onChange={handleRoleChange}
                >
                {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>{role.role}</MenuItem>
                ))}
                </Select>
              </FormControl>
            </div>
              <div className='d-flex' style={{width:'100%'}}>
                <TextField
                required
                id="outlined-required"
                label="First Name"
                value={fName}
                onChange={fNameOnchange}
                />
                <TextField
                required
                id="outlined-required"
                label="Last Name"
                value={lName}
                onChange={lNameOnchange}
                />
              </div>
              <div className='d-flex' style={{width:'100%'}}>
                <TextField
                required
                id="outlined-required"
                label="Password"
                value={password}
                onChange={passwordOnchange}
                />
              </div>
              <Button variant="contained" onClick={submitForm}>Submit</Button>
          </Box>
          <Snackbar
            open={snackbarProps?.open}
            autoHideDuration={6000}
            onClose={snackbarProps.onClose}
            message={snackbarProps.text}
          />
        </div>
          
      );
    }
    
}
export default CreateUser