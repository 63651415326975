import { Delete, HighlightOff, MoreVert, CheckCircleOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, CardHeader, FormControl, IconButton, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Snackbar, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ISingleTicketProps, ITicketCategoryProps } from '../Helpers/TicketInterfaces';
import { Endpoints, IBuildResponseProps } from '../Helpers/ApiEndpoints';
import { DataPrepare } from '../Helpers/DataPrepare';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';

interface ISnackBarProps{
    open:boolean;
    onClose:undefined | (() => void);
    text:string | undefined;
  }
  
  class SnackBar implements ISnackBarProps{
    constructor(
      public open:boolean,
      public onClose: undefined | (() => void),
      public text:string | undefined,
    ){}
  }

function CreateTicket(){

    const [categories, setCategories] = useState<ITicketCategoryProps[]>([]);
    const [category, setCategory] = useState('');
    const [subject, setSubject] = useState('');
    const [ticketBody, setTicketBody] = useState('');
    const [initialising, setInitialising] = useState<boolean>(true)
    const navigate = useNavigate();
    const[snackbarProps, setSnackBarProps] = useState<ISnackBarProps>(new SnackBar(false, undefined, undefined))

    const endpoints = new Endpoints();
    const prepareData = new DataPrepare();
  
    function handleCatChange(event: SelectChangeEvent) {
      
        setCategory(event.target.value as string);
        console.log(category);
    }
  
    function subjectOnchange(event: React.ChangeEvent<HTMLInputElement>){
      setSubject(event.currentTarget.value);
      
    }
  
    function ticketBodyOnchange(event: React.ChangeEvent<HTMLInputElement>){
      setTicketBody(event.currentTarget.value);
      
    }

    async function getCategories(){
        try{
          const response:IBuildResponseProps = await endpoints.getCategories();
          const preparedCategories:ITicketCategoryProps[] = prepareData.prepareCategories(response.data);
          setCategories(preparedCategories);
          console.log(preparedCategories);
          setInitialising(false)
        }catch(error){
          throw error
        }
    }

    async function submitForm(){
        const payload = {'ticketSubject':subject, 'ticketBody':ticketBody, 'ticketCategory':category}
        try{
          const response:IBuildResponseProps = await endpoints.createTicket(payload);
          console.log(response)
          setSnackBarProps({open:true, onClose:submitForm, text:'Ticket Created.'});
          resetForm();
        }catch(error){
          throw error
        }
      }

    function resetForm(){
    setCategory('');
    setTicketBody('');
    setSubject('');
    }

    useEffect(()=>{
        getCategories();
      },[])

    if(initialising){
        return(<></>);
    }else{
        return(
            <div style={{width:'100%', height:'100%'}}>
              <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },display:'flex', flexDirection:'column', width:'100%', alignItems:'center'
            }}
            noValidate
            autoComplete="off">
              
              <div className='d-flex' style={{width:'100%'}}>
              
              <TextField
                  required
                  id="outlined-required"
                  label="Ticket Subject"
                  value={subject}
                  onChange={subjectOnchange}
                />
                <FormControl fullWidth sx={{ m: 1, minWidth: 232, maxWidth:232 }}>
                <InputLabel id="demo-simple-select-label">Ticket Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                sx={{flexGrow:'1'}}
                label="Ticket Category"
                value={category}
                onChange={handleCatChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>{category.category}</MenuItem>
                ))}
              </Select>
              </FormControl>
              </div>
              <div className='d-flex' style={{width:'100%'}}>
                <TextField
                id="outlined-multiline-flexible"
                label="Explain your issue"
                multiline
                rows={10}
                sx={{maxWidth:'100%'}}
                value={ticketBody}
                onChange={ticketBodyOnchange}
              />
              </div>
      
              <Button variant="contained" onClick={submitForm}>Submit</Button>
              
              </Box>
              <Snackbar
                  open={snackbarProps?.open}
                  autoHideDuration={6000}
                  onClose={snackbarProps.onClose}
                  message={snackbarProps.text}
                />
            </div>
              
          );
    }
    
}
export default CreateTicket