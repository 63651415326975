import * as React from 'react';
import '../layout.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../Reusable Components/Context Providers/AuthContextProvider';

interface ISideBarButtonProps{
    icon:React.ReactNode;
    text:string;
    route:string;
    permissionLevel:number;
}

function SideBarButton(props: ISideBarButtonProps){

    const{
        icon,
        text,
        route,
        permissionLevel
    } = props;

    const navigate = useNavigate();

    const location = useLocation();

    const {state} = useAuthContext();

    if(state.loginDetails && state.loginDetails.permissionLevel >= permissionLevel){
        return(
            <div className={location.pathname.includes(route) ? 'sideBarButton activeSideBarButton' : 'sideBarButton'} onClick={() => navigate(`${route}`)}>
                {icon}<span style={{fontWeight:'600'}}>{text}</span>
            </div>
        )
    }else{
        return(<></>)
    }
    
}
export default React.memo(SideBarButton)