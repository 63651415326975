export interface IUserDetailsProps{
    firstName:string;
    lastName:string;
    email:string;
    picture?:Blob;
}

export interface ILoginDetailsProps{
    permissionLevel:number;
    user:IUserDetailsProps;
}

export class UserDetails implements IUserDetailsProps{
    constructor (
        public firstName: string,
        public lastName: string,
        public email: string,
        public picture?: Blob,
    ) {}
}

export class LoginDetails implements ILoginDetailsProps{
    constructor (
        public permissionLevel:number,
        public user:IUserDetailsProps,
    ) {}
}
