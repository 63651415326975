export interface ITicketProps{
    category: string;
    firstName: string;
    lastName:string;
    ticketDate:Date;
    ticketID:number;
    ticketStatus:string;
    ticketSubject:string;
}

export class Ticket implements ITicketProps{
    constructor (
        public category: string,
        public firstName: string,
        public lastName:string,
        public ticketDate:Date,
        public ticketID:number,
        public ticketStatus:string,
        public ticketSubject:string,
    ){}
}

export interface ISingleTicketProps{
    category: string;
    firstName: string;
    lastName:string;
    ticketBody:string;
    ticketDate:Date;
    ticketID:number;
    ticketStatus:string;
    ticketSubject:string;
}

export class SingleTicket implements ISingleTicketProps{
    constructor (
        public category: string,
        public firstName: string,
        public lastName:string,
        public ticketBody:string,
        public ticketDate:Date,
        public ticketID:number,
        public ticketStatus:string,
        public ticketSubject:string,
    ){}
}

export interface ITicketCategoryProps{
    id:number;
    category:string;
}

export class Category implements ITicketCategoryProps{
    constructor(
        public id:number,
        public category:string,
    ){}
}