import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';
import { Endpoints } from '../Helpers/ApiEndpoints';


function SignOut() {
    const { dispatch } = useAuthContext();
    const navigate = useNavigate();
    const endpoints = new Endpoints();

    useEffect(() => {
        const logout = async () => {
            try {
                await endpoints.logOut();
                dispatch({ type: 'REMOVE_USER' });
                localStorage.removeItem('loginDetails');
                navigate('/login');
            } catch (error) {
                console.error('Error during logout:', error);
            }
        };

        logout();
    }, [dispatch, navigate, endpoints]);

    return <div>Signing out...</div>;
}

export default SignOut;