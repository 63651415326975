import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from './Context Providers/AuthContextProvider';

interface IProtectedRoutesProps {
    children: React.ReactNode;
    permissionLevel:number;
}

function ProtectedRoutes({ children, permissionLevel }: IProtectedRoutesProps) {
    const { state } = useAuthContext();

    if (!state.loginDetails) {
        
        return <Navigate to='/login' replace />;
    }else{
        if(state.loginDetails.permissionLevel < permissionLevel){
            return <Navigate to='/dashboard' replace />;
        }else{
            return <>{children}</>;
        }
    }
}

export default ProtectedRoutes;