import * as React from 'react';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Endpoints, IBuildResponseProps } from '../../Helpers/ApiEndpoints';
import { useNavigate } from 'react-router-dom';
import { ILoginDetailsProps } from '../../Helpers/AuthInterface';

interface IAuthState {
    loginDetails: ILoginDetailsProps | null;
}

type Action =
    | { type: 'SET_USER'; payload: ILoginDetailsProps }
    | { type: 'REMOVE_USER' };

const initialState: IAuthState = { loginDetails: null };

const authReducer = (state: IAuthState, action: Action): IAuthState => {
    switch (action.type) {
        case 'SET_USER':
            return { loginDetails: action.payload };
        case 'REMOVE_USER':
            return { loginDetails: null };
        default:
            return state;
    }
};

const AuthContext = createContext<{
    state: IAuthState;
    dispatch: React.Dispatch<Action>;
} | undefined>(undefined);

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};

interface AuthContextProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const [loading, setLoading] = useState<boolean>(true);
    const endpoints = new Endpoints();

    // Authenticate user on initial load or refresh
    useEffect(() => {
        const savedLoginDetails = localStorage.getItem('loginDetails');
        if (savedLoginDetails) {
            const parsedDetails = JSON.parse(savedLoginDetails);
            dispatch({ type: 'SET_USER', payload: parsedDetails });
            setLoading(false);
        } else {
            authMe();
        }
    }, []);

    useEffect(() => {
        if (state.loginDetails) {
            localStorage.setItem('loginDetails', JSON.stringify(state.loginDetails));
        } else {
            localStorage.removeItem('loginDetails');
        }
    }, [state.loginDetails]);

    // Function to authenticate user
    const authMe = async () => {
        try {
            const response: IBuildResponseProps = await endpoints.authMe();
            if (response.success) {
                dispatch({ type: 'SET_USER', payload: response.data });
            } else {
                dispatch({ type: 'REMOVE_USER' });
            }
        } catch (error) {
            console.error('Failed to authenticate user:', error);
            dispatch({ type: 'REMOVE_USER' });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};