import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Endpoints, IBuildResponseProps } from '../Helpers/ApiEndpoints';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';
import { DataPrepare } from '../Helpers/DataPrepare';
import { Navigate, useNavigate } from 'react-router-dom';

function Login(){

    const[email, setEmail] = useState<string>('');
    const[password, setPassword] = useState<string>('');

    const endpoints = new Endpoints();
    const prepareData = new DataPrepare();

    const {dispatch, state} = useAuthContext();

    const navigate = useNavigate();

    async function login(e:React.MouseEvent<HTMLButtonElement>){
      //use try catch
      e.preventDefault();
      const payload = {email:email, password:password};
      try{
        let response:IBuildResponseProps = await endpoints.login(payload);
        let loginDetails = prepareData.prepareLoginResponseForStorage(response.data);
        if(!response.success){
          throw new Error('error logging in')
        }else{
          dispatch({type: 'SET_USER', payload:loginDetails});
          navigate('/');
        }
      }catch(error){
        console.log(error);
        throw new Error('An error occurred when communicating with the API.');
      }
    }

    return(
        <div className='loginPageWrapper' style={{display:'flex', width:'100%', height:'100vh', justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
            <div className='loginContainer' style={{display:'flex', maxWidth:'400px', maxHeight:'400px', width:'100%', height:'100%', backgroundColor:'lightgray', padding:'20px', boxSizing:'border-box', flexDirection:'column', gap:'30px'}}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e)=>setEmail(e.currentTarget.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Example textarea</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.currentTarget.value)}/>
                </Form.Group>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                  <Button variant="primary" type="submit" onClick={(e)=>login(e)}>
                    Login
                  </Button>
                    <Button variant="primary" type="button" onClick={(e) => {e.preventDefault(); navigate('/signup')}}>
                      Sign Up
                    </Button>
                </div>
              </Form>
            </div>
        </div>
    );
}

export default Login